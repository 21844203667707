<mat-card class="flex-wrapper fix-card">
    <div class="container flex-row center font-large mt-10" style="font-weight: 500;">
        {{ deviceName$ | async }}
    </div>
    <div *ngIf="(series$|async)===undefined" class="spinner-container">
        <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
    </div>
    <div *ngIf="(series$|async)!==undefined && intervalsArray!==null && intervalsArray.length === 0 && (currentData | async) == null" class="spinner-container">
        <div class="container centered-content">
            <div>
                <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
            </div>
            <div>No Data Found</div>
        </div>
    </div>
    <div *ngIf="(series$|async)!==undefined && (intervalsArray!==null || (currentData | async) != null)">
        <camera-snapshot class="flex-row align-center center" [intervals]="intervals$ | async" [snapType]="snapType"
            [currentInterval]="currentInterval$ | async" (nextClick)="handleNextClick()"
            (previousClick)="handlePreviousClick()" (refreshClick)="refresh()">
        </camera-snapshot>
        <div *ngIf="showInterval === false">
            <div *ngFor="let interval of intervalsArray; let i = index">
                <mat-card-content *ngIf="i == currentInterval" class="flex-row wrap around card-content-scroll">
                    <div class="flex-row full-width">
                        <card-square-blocks class="container-50" [title]="'Begin'" [warn]="false" [size]="'medium'"
                            [long]="true">
                            {{
                            intervalsArray[i+1].begin | date : getFormattedDateTime()
                            }}</card-square-blocks>
                        <card-square-blocks class="container-50" [title]="'End'" [warn]="false" [size]="'medium'"
                            [long]="true">
                            {{
                            intervalsArray[i+1].end | date : getFormattedDateTime()
                            }}</card-square-blocks>
                    </div>
                    <div *ngFor="let keys of (percents$ | async).keys(); let j = index">
                        <card-square-blocks [title]="'D' + keys" [size]="'medium'" [warn]="false">
                            {{ intervalsArray[i+1].data[j + 1] | unitConversion:  unitSystem }}
                        </card-square-blocks>
                    </div>
                </mat-card-content>
            </div>
        </div>
        <div *ngIf="(currentData | async) && showInterval === true">
            <mat-card-content class="flex-row wrap around card-content-scroll">
                <div class="flex-row justify full-width">
                    <div class="full-width">
                        <card-square-blocks [title]="'Begin'" [warn]="false" [size]="'medium'" [long]="true">
                            {{
                            (currentData | async).begin | date : getFormattedDateTime()
                            }}</card-square-blocks>
                    </div>
                    <div class="full-width">
                        <card-square-blocks [title]="'End'" [warn]="false" [size]="'medium'" [long]="true">
                            {{
                            (currentData | async).end | date : getFormattedDateTime()
                            }}</card-square-blocks>
                    </div>
                </div>
                <div *ngFor="let keys of (percents$ | async).keys(); let j = index">
                    <card-square-blocks [title]="'D' + keys" [size]="'medium'" [warn]="false">
                        {{ (currentData | async).data[j + 1] | unitConversion:  unitSystem}}
                    </card-square-blocks>
                </div>
            </mat-card-content>
        </div>
    </div>
</mat-card>